<template>
  <div>
    <Heard />
    <!-- <Tip /> -->
    <div class="container person-box">
      <Aside />
      <div class="right-box">
        <div class="phone-box">
          <p class="number">
            已绑定手机号码 : <span>{{ user.mobile }}</span>
          </p>
          <p class="suc">
            <i class="el-icon-warning"></i>
            友情提示：由于安全软件的设置，短信有可能被手机安全软件拦截，如果没收到短信，请查看拦截设置
          </p>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { getUser } from "@/plugins/auth.js";

import Heard from "@/components/Heard.vue";
// import Tip from "@/components/Tip.vue";
import Aside from "@/components/Aside.vue";
import Footer from "@/components/Footer.vue";
export default {
  components: {
    Heard,
    // Tip,
    Aside,
    Footer,
  },
  data() {
    return {
      user: {},
    };
  },
  created() {
    this.user = getUser();
  },
  computed: {
    info() {
      return this.$store.state.userstore.userInfo;
    },
  },
};
</script>

<style lang="less" scoped>
.person-box {
  display: flex;
  .right-box {
    flex: 1;
    border: 1px solid #f2f2f2;
    position: relative;
    .phone-box {
      .number {
        margin-left: 50px;
        margin-top: 120px;
      }
      .suc {
        position: absolute;
        bottom: 40px;
        left: 40px;
        border: 1px solid #91d5ff;
        background: #e6f7ff;
        border-radius: 2px;
        height: 40px;
        line-height: 40px;
        padding: 0 20px;
        font-size: 14px;
        .el-icon-warning {
          color: #1890ff;
        }
      }
    }
  }
}
</style>